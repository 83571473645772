<template>
  <div class="missson-adjustment-record">
    <headerNav title="调整记录" :leftIcon="true" @leftClick="back" />
    <div class="content">
      <div v-for="item in list" :key="item.id" class="item-card">
        <div class="card-head">
          <span class="left-text">{{ item.taskNo }}</span>
          <span class="right-text">{{ transverter(item.createDate) }}</span>
        </div>
        <div class="card-center">
          <span class="card-title">提&ensp;交&ensp;人：</span>
          <span class="card-text">{{ item.createName }}</span>
        </div>
        <div class="card-content">
          <span class="card-title">调整内容：</span>
          <ul class="card-text">
            <li v-for="item2 in item.itemList">
            {{ item2.name }}{{ item2.taskNum }}<img :src="require('@/assets/a38.svg')" alt="">{{ item2.realityNum }}
            </li>
          </ul>
        </div>
        <div class="card-bottom" @click="toDetails(item.id)">
          <span>查看详情</span>
          <span><van-icon name="arrow" /></span>
        </div>
      </div>
      <div v-if="!list.length" class="empty-box">
        <img :src="require('@/assets/a-empty.png')" alt="">
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../api/api'
import moment from 'moment';

export default {
  data () {
    return {
      list: []
    }
  },
  methods: {
    //返回
    back () {
      this.$router.push({ name: this.$route.query.isHistory ? 'TaskAdvcost' : 'TaskAdvcostMissionAdjustment' })

    },
    async getAdjustMentRecord () {
      let obj = {
        taskPeriod: this.$route.params.taskPeriod,
        marketCode: this.$route.params.marketCode
      }
      let res = await api.getAdjustMentRecord(obj)
      this.list = res.data
    },
    //查看详情
    toDetails (id) {
      this.$router.push({
        name: 'TaskAdvcostMissionAdjustmentRecordDetails',
        params: {
          id: id
        }
      })

    },
    //日期转文字
    transverter (date) {
      let t = moment(date)

      return (t.month() + 1) + '月' + t.date() + '日' + ' ' + moment(date).format('YYYY-MM-DD HH:mm').split(' ')[1]
    }
  },
  mounted () { },
  created () {
    this.getAdjustMentRecord()

  }
}
</script>

<style scoped lang="scss">
.missson-adjustment-record {
  .empty-box {
    text-align: center;

    img {
      width: 100%;
      margin-top: 50%;
    }

    div {
      font-size: 15px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #5E5E5E;

    }
  }

  .content {
    height: calc(100vh - 50px);
    overflow: auto;
    background: #F5F5F5;
    text-align: left;

    .item-card {
      margin-top: 17px;
      background: #FFFFFF;
      border-radius: 8PX;
      padding: 0 12px;
      padding-top: 17PX;

      .card-head {
        height: 34px;
        display: flex;
        width: 100%;
        align-items: center;

        &>span {
          flex: 1;
        }

        .left-text {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          color: #1D2129;
          text-align: left;
        }

        .right-text {
          font-size: 15px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #8C8C8C;
          text-align: right;
        }
      }

      .card-center {
        margin-top: 15px;
      }


      .card-title,
      .card-text {
        font-size: 15px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #545454;
      }

      .card-text {
        color: #1D2129;
        font-family: PingFang SC-Medium;
        width: calc(100% - 90px);
        img{
          margin: 0 2px;
        }
      }

      .card-content {
        margin-top: 12px;
        display: flex;

        &>div {
          font-size: 15px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #1D2129;
          white-space: break-spaces;

        }
      }

      .card-bottom {
        height: 42px;
        display: flex;
        width: 100%;
        align-items: center;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #545454;
        border-top: 1px solid #EBEDF0;
        margin-top: 20px;

        &:active {
          background: rgba(245, 245, 245, 0.4);
          opacity: 0.8;
        }

        &>span {
          flex: 1;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>